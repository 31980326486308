<template>
  <section id="codes-standards">
    <pibot-section-heading><slot>Codes and Standards</slot></pibot-section-heading>

    <!-- Applicable Codes and Standards -->
    <pibot-asset-codes-card title="Applicable Codes and Standards" :items="codes" />
    <!-- Internal Requirements -->
    <pibot-asset-codes-card title="Internal Requirements" :items="requirements" />
    <!-- Planning Optimisation Rules -->
    <pibot-asset-codes-card title="Planning Optimisation Rules" :items="rules" />
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'
import CodesCard from './Card'

export default {
  name: 'pibot-asset-codes',
  components: {
    'pibot-section-heading': SectionHeading,
    'pibot-asset-codes-card': CodesCard
  },
  data () {
    return {
      codes: [
        {
          title: 'API 580',
          path: 'about:blank'
        },
        {
          title: 'API 581',
          path: 'about:blank'
        },
        {
          title: 'API RP 1173',
          path: 'about:blank'
        },
        {
          title: 'NEN 3650',
          path: 'about:blank'
        },
        {
          title: 'NEN 3655',
          path: 'about:blank'
        },
        {
          title: 'EN 16991',
          path: 'about:blank'
        },
        {
          title: 'EEMUA 159-17',
          path: 'about:blank'
        }
      ],
      requirements: [
        {
          title: 'Internal Requirements 2017',
          path: 'about:blank'
        }
      ],
      rules: [
        {
          title: 'Optimisation Rules',
          path: 'about:blank'
        }
      ]
    }
  }
}
</script>
