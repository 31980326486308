<template>
  <v-card :flat="$vuetify.breakpoint.mdAndUp" :class="{'mb-3': $vuetify.breakpoint.smAndDown}">
    <v-card-text :class="{'py-0': $vuetify.breakpoint.mdAndUp}">
      <v-layout wrap>
        <v-flex xs12 md4 :class="cardTitleClass">
          <p>{{title}}</p>
          <v-divider v-if="$vuetify.breakpoint.smAndDown" />
        </v-flex>
        <v-flex
          xs12
          md8
          :class="{'text-right': $vuetify.breakpoint.mdAndUp}"
        >
          <span v-for="(item, i) in items" :key="item.title">
            <span v-if="i > 0">, </span>
            <a :href="item.path" target="_blank">{{item.title}}</a>
          </span>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'pibot-asset-codes-card',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cardTitleClass () {
      return {
        'font-weight-medium': this.$vuetify.breakpoint.mdAndUp,
        title: this.$vuetify.breakpoint.smAndDown,
        'mb-5': this.$vuetify.breakpoint.smAndDown
      }
    }
  }
}
</script>
